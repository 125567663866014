import React from "react";

const Spinner = () => {
    return (
        <div class="spinner-containner">
	    <div class="spinner"></div>
	</div>
    );
};

export default Spinner;
